<template>
  <div class="nav-left" role="menu">
    <div class="nav-wrap">
      <div
        class="brand-wrap"
        v-bind:class="[this.$root.production ? 'on-air' : 'test']"
        v-bind:title="this.$root.store.event.eventName"
      >
        <p v-if="this.$root.production">ON AIR</p>
        <p v-else>TEST</p>
      </div>
      <nav class="primary-menu">
        <ul>
          <li v-bind:class="[currentPage === '/' ? 'active' : '']">
            <router-link :to="{ name: 'dashboard' }"> Oversigt </router-link>
          </li>
          <li v-bind:class="[currentPage === '/smscampaigns' ? 'active' : '']">
            <router-link :to="{ name: 'smscampaigns' }"> SMS </router-link>
          </li>
          <li
            v-bind:class="[
              currentPage === '/businesspayments' ||
              currentPage === '/businesspayments/approval' ||
              currentPage === '/businesspayments/create'
                ? 'active'
                : '',
            ]"
          >
            <router-link :to="{ name: 'businesspayments' }">
              TV-Crawler
            </router-link>
          </li>
          <li v-bind:class="[currentPage === '/eventpayments' ? 'active' : '']">
            <router-link :to="{ name: 'eventpayments' }">
              Betalinger via API
            </router-link>
          </li>
          <li v-bind:class="[currentPage === '/corrections' ? 'active' : '']">
            <router-link :to="{ name: 'corrections' }">
              Korrektioner
            </router-link>
          </li>
        </ul>
      </nav>
      <nav class="support-menu">
        <ul>
          <li v-if="$root.callCenterUrl && this.$root.administrator">
            <a
              v-bind:href="$root.callCenterUrl"
              target="_blank"
              rel="noopener noreferrer"
              >Callcenter</a
            >
          </li>
          <li v-if="$root.tvCrawlerUrl && this.$root.administrator">
            <a
              v-bind:href="$root.tvCrawlerUrl"
              target="_blank"
              rel="noopener noreferrer"
              >TV-Crawler XML</a
            >
          </li>
        </ul>
        <ul class="logout">
          <li>
            <logout />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import Logout from "../account/Logout";

export default {
  name: "NavbarLeft",
  components: {
    Logout,
  },
  computed: {
    currentPage() {
      return this.$route.path;
    },
  },
};
</script>
