<template>
  <div>
    <!-- Error -->
    <p v-if="this.listErrored">Fejl ...</p>

    <!-- Loading -->
    <p v-else-if="this.listLoading">Indlæser ...</p>

    <!-- List -->
    <div v-else class="winners">
      <h4>Vinder(e)</h4>
      <template v-if="this.winners.length">
        <div
          v-for="winner in this.winners"
          v-bind:key="winner.messageGuid"
          class="flex-container flex-space-between"
        >
          <a
            v-bind:href="$root.callCenterUrl + '?pre_phone=' + winner.msisdn"
            class="winner-msisdn"
            target="_blank"
            rel="noopener noreferrer"
            title="Forsøg at finde vinderens navn"
            v-bind:inner-html.prop="winner.msisdn | formatMsisdn"
          ></a>
          <span class="text-align-right">
            <span
              title="Tidspunkt for opkrævning"
              v-bind:inner-html.prop="winner.chargedTs | formatTime"
            ></span
            ><br />
            <span
              v-bind:class="{
                green: winner.charged === true,
                red: winner.charged === false && winner.state === 'Invalid',
                blue: winner.charged === false && winner.state === 'Pending',
              }"
              >{{ winner.state }}</span
            >
          </span>
        </div>
      </template>
      <p v-else>Afventer ...</p>
    </div>
  </div>
</template>

<script>
import { formats } from "../../mixins/formats.js";
import axios from "axios";

export default {
  name: "SmsWinners",
  mixins: [formats],
  props: {
    campaign: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      winners: [],
      listErrored: false,
      listLoading: true,
      listChecker: null,
      refreshInterval: 5000, // five seconds
    };
  },
  created() {
    //  Get list
    this.getWinners();
    this.getWinnersContinuously();
  },
  methods: {
    /**
     * Get winners
     */
    async getWinners() {
      this.listLoading = true;

      //  Request
      try {
        const response = await axios.get(
          this.$root.apiUrl +
            "/sms/campaign/" +
            this.campaign.campaign.campaignGuid +
            "/Winners",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );
        this.listLoading = false;
        this.listErrored = false;
        this.winners = response.data;

        this.checkStopWinnersContinuously();

        //  Emit to parent component
        this.$emit("winnersFound");
      } catch (error) {
        this.listLoading = false;
        this.listErrored = true;

        //  Error
        this.$root.handleErrorResponse(error);
      }
    },

    /**
     * Get winners continuously
     */
    getWinnersContinuously() {
      //  Log
      window.console.log(
        "Lottery: " +
          this.campaign.campaign.campaignName +
          ". Started winners refreshing cycle"
      );

      this.listChecker = setInterval(
        function () {
          //  Skip if error
          if (this.listErrored) {
            //  Log
            window.console.log(
              "Lottery: " +
                this.campaign.campaign.campaignName +
                ". Stopped winners refreshing cycle due to error"
            );
          }

          //  Request
          else {
            //  Log
            window.console.log(
              "Lottery: " +
                this.campaign.campaign.campaignName +
                ". Refreshing winners every " +
                this.refreshInterval / 1000 +
                " seconds"
            );
            this.getWinners();
          }
        }.bind(this),
        this.refreshInterval
      );
    },

    /**
     * Check if all winners have been found
     */
    checkStopWinnersContinuously() {
      if (this.campaign.campaign.winnerCount == this.winners.length) {
        //  Log
        window.console.log(
          "Lottery: " +
            this.campaign.campaign.campaignName +
            ". Stopped winners refreshing cycle as campaign is complete"
        );

        clearInterval(this.listChecker);
      }
    },
  },
};
</script>
