<template>
  <div class="wrap dashboard">
    <!-- Trigger aggregation -->
    <div style="position: fixed; top: 25px; right: 15px; z-index: 999">
      <button
        v-if="this.$root.administrator"
        class="uik-btn__base btn btn-tiny"
        v-on:click="triggerAggregation"
      >
        Anmod optælling nu
      </button>
    </div>

    <div v-if="this.chartDataCollection" class="content">
      <!-- Latest -->
      <div
        v-if="this.latestEventCount"
        class="row chart-counter"
        style="margin-top: 25px"
      >
        <div
          class="col-sm-4"
          v-bind:title="
            'Ikke-skaleret: ' + this.latestEventCount.sumOfDonations + ' DKK'
          "
        >
          Indsamlet
          <p>
            {{ this.latestEventCount.scaledSumOfDonations | formatAmount }}
            DKK
          </p>
        </div>
        <div class="col-sm-4 text-align-center">
          Antal donationer
          <p>
            {{ this.latestEventCount.countOfDonations | formatAmount }}
          </p>
        </div>
        <div class="col-sm-4 text-align-right">
          Optalt
          <p
            v-bind:inner-html.prop="this.latestEventCount.statusTs | formatHour"
          ></p>
          <span style="font-size: 16px; opacity: 0.75"
            >Optælles hvert
            {{ currentAggregationFrequencyInMinutesString }}</span
          >
        </div>
      </div>

      <!-- Event -->
      <div class="row charts">
        <div class="col-sm-12">
          <div
            style="
              padding: 10px 10px 10px 0;
              border-radius: 10px;
              box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
              position: relative;
              height: 440px;
              background: #fff;
              overflow: hidden;
            "
          >
            <!-- Navigation -->
            <div
              style="
                position: absolute;
                top: 10px;
                left: 10px;
                display: flex;
                z-index: 99;
              "
            >
              <button
                type="button"
                class="uik-btn__base btn btn-tiny"
                v-bind:class="{ active: showSimpleChartSeries }"
                v-on:click="showSimpleChartSeries = true"
              >
                Overblik
              </button>
              <button
                type="button"
                class="uik-btn__base btn btn-tiny"
                v-bind:class="{ active: !showSimpleChartSeries }"
                v-on:click="buildDetailedChartSeries()"
              >
                Detaljer
              </button>
            </div>

            <!-- Simple Chart -->
            <div
              class="charts-container"
              v-bind:class="{ active: showSimpleChartSeries }"
            >
              <apexchart
                type="line"
                height="400"
                ref="apexChart"
                v-bind:options="this.apexChartOptions"
                v-bind:series="this.apexChartSeries"
              />
            </div>

            <!-- Detailed Chart -->
            <div
              class="charts-container"
              v-bind:class="{ active: !showSimpleChartSeries }"
            >
              <apexchart
                type="area"
                v-if="isDetailedChartSeriesReady"
                height="400"
                ref="apexChart"
                v-bind:options="this.apexChartOverviewOptions"
                v-bind:series="this.apexChartOverviewSeries"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Lotteries -->
      <!-- <lotteries v-bind:context="'Dashboard'" /> -->
    </div>
  </div>
</template>

<script>
//  import Lotteries from "../lotteries/Lotteries.vue";
import axios from "axios";
import { formats } from "../../mixins/formats.js";

export default {
  // components: {
  //   Lotteries,
  // },
  mixins: [formats],
  data() {
    return {
      showSimpleChartSeries: true,
      isDetailedChartSeriesReady: false,
      latestEventCount: null,
      chartDataCollection: null,
      apexChartOptions: {
        chart: {
          type: "area",
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: true,
          },
        },
        colors: [
          "#008ffb",
          "#00e396",
          "#feb019",
          "#ff4560",
          "#775dd0",
          "#be5dd0",
          "#d0cc5d",
          "#5dd0bb",
          "#5d8bd0",
        ],
        fill: {
          type: "solid",
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          x: {
            show: false,
          },
          shared: true,
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return val ? val.toLocaleString("da") : val;
            },
          },
        },
        noData: {
          text: "Data ikke fundet",
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "#ddd",
            fontSize: "40px",
          },
        },
      },
      apexChartSeries: [
        {
          name: "Indsamlet",
          type: "area",
          data: [],
        },
        // {
        //   name: "Estimat",
        //   type: "line",
        //   data: [],
        // },
      ],
      apexChartOverviewOptions: {
        chart: {
          type: "area",
          stacked: true,
          zoom: {
            enabled: true,
            type: "x",
            autoScaleYaxis: true,
          },
        },
        colors: [
          "#008ffb",
          "#00e396",
          "#feb019",
          "#ff4560",
          "#775dd0",
          "#be5dd0",
          "#d0cc5d",
          "#5dd0bb",
          "#5d8bd0",
        ],
        fill: {
          type: "solid",
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          x: {
            show: false,
          },
          shared: true,
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return val ? val.toLocaleString("da") : val;
            },
          },
        },
        noData: {
          text: "Data ikke fundet",
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "#ddd",
            fontSize: "40px",
          },
        },
      },
      apexChartOverviewSeries: [
        {
          name: "SMS Lodtrækning", // 0
          data: [],
        },
        {
          name: "SMS Donation", // 1
          data: [],
        },
        {
          name: "MobilePay MyShop", // 2
          data: [],
        },
        {
          name: "MobilePay Online", // 3
          data: [],
        },
        {
          name: "Betalingskort", // 4
          data: [],
        },
        {
          name: "Betalingsservice", // 5
          data: [],
        },
        {
          name: "Korrektioner", // 6
          data: [],
        },
        {
          name: "Øvrige, til TV-Crawler", // 7
          data: [],
        },
        {
          name: "Øvrige, inkl. API, under beløbsgrænse", // 8
          data: [],
        },
        // {
        //  name: "SMS 3. part", // 9
        //  data: [],
        // },
      ],
      chartDataChecker: null,
    };
  },
  created() {
    //  Log
    window.console.log("Chart: init");

    //  Request
    this.getChartData().then((response) => {
      this.chartDataCollection = response;
      this.buildSimpleChartSeries();

      //  Request continuously
      this.getChartsContinuously();
    });
  },
  destroyed() {
    //  Log
    window.console.log("Chart: stop refreshing");

    clearInterval(this.chartDataChecker);
  },
  computed: {
    /**
     * Get frequency string
     */
    currentAggregationFrequencyInMinutesString() {
      if (this.$root.store.event) {
        const frequencyInMinutes =
          this.$root.store.event.aggregationFrequencyInMinutes;

        //  Default
        let frequencyText = frequencyInMinutes + ". min.";

        //  Parse hours
        if (frequencyInMinutes > 60) {
          if (frequencyInMinutes / 60 === 1) {
            frequencyText = "time";
          } else {
            frequencyText = frequencyInMinutes / 60 + ". time";
          }
        }

        return frequencyText;
      } else {
        return "15. min.";
      }
    },
  },
  methods: {
    async triggerAggregation() {
      //  Request
      await axios
        .post(this.$root.apiUrl + "/event/triggerAggregation", null, {
          headers: {
            "Content-Type": "application/json",
            Authorization: this.$root.user.authToken,
          },
        })
        .then(() => {
          this.$root.successResponse(
            "Optællingen er anmodet, vent venligst.",
            null
          );
        })
        .catch((error) => {
          console.log(error);
          //  Error
          // this.$root.handleErrorResponse(error);
          this.$root.handleErrorResponse(
            "Det var ikke muligt at anmode om en optælling.",
            "Det var ikke muligt at anmode om en optælling."
          );
        });
    },
    /**
     * Build simple chart series from chart data collection
     */
    buildSimpleChartSeries() {
      //  Sum (Indsamlet)
      this.apexChartSeries[0].data = this.chartDataCollection.EVENT.map((a) => {
        return { x: a.statusTs, y: a.scaledSumOfDonations };
      });

      // //  Prediction (Estimat)
      // this.apexChartSeries[0].data.push({
      //   x: "2022-09-17T19:37:00Z",
      //   y: 0,
      // });
      // this.apexChartSeries[0].data.push({
      //   x: "2022-09-17T19:37:00Z",
      //   y: 8000000,
      // });
      // this.apexChartSeries[0].data.push({
      //   x: "2022-09-17T20:22:00Z",
      //   y: 9000000,
      // });
      // this.apexChartSeries[0].data.push({
      //   x: "2022-09-17T21:02:00Z",
      //   y: 11000000,
      // });
      // this.apexChartSeries[0].data.push({
      //   x: "2022-09-17T21:37:00Z",
      //   y: 14000000,
      // });
      // this.apexChartSeries[0].data.push({
      //   x: "2022-09-17T22:02:00Z",
      //   y: 16000000,
      // });
    },

    /**
     * Build detailed chart series from chart data collection
     */
    buildDetailedChartSeries() {
      //  Hide chart
      this.showSimpleChartSeries = false;

      //  Chart not loaded
      if (!this.isDetailedChartSeriesReady) {
        //  Log
        //  window.console.log(this.chartDataCollection);

        this.apexChartOverviewSeries[0].data =
          this.mapStatusType("SMS_LOTTERY");
        this.apexChartOverviewSeries[1].data = this.mapStatusType("SMS");
        this.apexChartOverviewSeries[2].data =
          this.mapStatusType("MOBILE_PAY_MYSHOP");
        this.apexChartOverviewSeries[3].data =
          this.mapStatusType("MOBILE_PAY_ONLINE");
        this.apexChartOverviewSeries[4].data = this.mapStatusType("CARD");
        this.apexChartOverviewSeries[5].data = this.mapStatusType(
          "BETALINGSSERVICE_SINGLE"
        );
        this.apexChartOverviewSeries[6].data = this.mapStatusType("CORRECTION");
        this.apexChartOverviewSeries[7].data =
          this.mapStatusType("BUSINESS_PAYMENT");
        this.apexChartOverviewSeries[8].data =
          this.mapStatusType("EVENT_PAYMENT");
        // this.apexChartOverviewSeries[9].data = this.mapStatusType("SMS_THIRD_PARTY");

        //  Log
        //  window.console.log(this.apexChartOverviewSeries);

        //  Chart loaded
        this.isDetailedChartSeriesReady = true;
      }
    },

    /**
     * Helper function to map status types
     */
    mapStatusType(statusType) {
      if (typeof this.chartDataCollection[statusType] !== "undefined") {
        //  Log
        //  window.console.log(statusType);

        return this.chartDataCollection[statusType].map((a) => {
          return { x: a.statusTs, y: a.scaledSumOfDonations };
        });
      } else {
        return [];
      }
    },

    /**
     * Get charts every 2 minutes
     */
    getChartsContinuously() {
      //  Log
      window.console.log("Chart: start refreshing cycle");

      this.chartDataChecker = setInterval(
        function () {
          //  Log
          window.console.log("Chart: refreshing");

          //  Request
          this.getChartData().then((response) => {
            this.chartDataCollection = response;

            if (this.showSimpleChartSeries) {
              this.buildSimpleChartSeries();
            } else {
              this.buildDetailedChartSeries();
            }

            //  Log
            window.console.log("Chart: refreshed");
          });
        }.bind(this),
        this.$root.refreshInterval
      );
    },

    /**
     * Get chart data
     */
    async getChartData() {
      //  Request
      try {
        const response = await axios.get(
          this.$root.apiUrl + "/event/chartdata",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.$root.user.authToken,
            },
          }
        );

        //  Set latest count
        this.defineLatestEventCount(response.data);

        //  Success
        return response.data;
      } catch (error) {
        //  Error
        this.$root.handleErrorResponse(error);
        return null;
      }
    },

    /**
     * Define the latest event count .. assuming the last Event object in the array is the latest
     * @param {array} dataCollection
     */
    defineLatestEventCount(dataCollection) {
      if (typeof dataCollection["EVENT"] !== "undefined") {
        const lastestCollection = dataCollection["EVENT"].length - 1;
        this.latestEventCount = dataCollection["EVENT"][lastestCollection];
      }
    },
  },
};
</script>
