var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-left",attrs:{"role":"menu"}},[_c('div',{staticClass:"nav-wrap"},[_c('div',{staticClass:"brand-wrap",class:[this.$root.production ? 'on-air' : 'test'],attrs:{"title":this.$root.store.event.eventName}},[(this.$root.production)?_c('p',[_vm._v("ON AIR")]):_c('p',[_vm._v("TEST")])]),_c('nav',{staticClass:"primary-menu"},[_c('ul',[_c('li',{class:[_vm.currentPage === '/' ? 'active' : '']},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_vm._v(" Oversigt ")])],1),_c('li',{class:[_vm.currentPage === '/smscampaigns' ? 'active' : '']},[_c('router-link',{attrs:{"to":{ name: 'smscampaigns' }}},[_vm._v(" SMS ")])],1),_c('li',{class:[
            _vm.currentPage === '/businesspayments' ||
            _vm.currentPage === '/businesspayments/approval' ||
            _vm.currentPage === '/businesspayments/create'
              ? 'active'
              : '',
          ]},[_c('router-link',{attrs:{"to":{ name: 'businesspayments' }}},[_vm._v(" TV-Crawler ")])],1),_c('li',{class:[_vm.currentPage === '/eventpayments' ? 'active' : '']},[_c('router-link',{attrs:{"to":{ name: 'eventpayments' }}},[_vm._v(" Betalinger via API ")])],1),_c('li',{class:[_vm.currentPage === '/corrections' ? 'active' : '']},[_c('router-link',{attrs:{"to":{ name: 'corrections' }}},[_vm._v(" Korrektioner ")])],1)])]),_c('nav',{staticClass:"support-menu"},[_c('ul',[(_vm.$root.callCenterUrl && this.$root.administrator)?_c('li',[_c('a',{attrs:{"href":_vm.$root.callCenterUrl,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("Callcenter")])]):_vm._e(),(_vm.$root.tvCrawlerUrl && this.$root.administrator)?_c('li',[_c('a',{attrs:{"href":_vm.$root.tvCrawlerUrl,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("TV-Crawler XML")])]):_vm._e()]),_c('ul',{staticClass:"logout"},[_c('li',[_c('logout')],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }